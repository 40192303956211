"use strict";

(function () {
	function r(e, n, t) {
		function o(i, f) {
			if (!n[i]) {
				if (!e[i]) {
					var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
				}var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
					var n = e[i][1][r];return o(n || r);
				}, p, p.exports, r, e, n, t);
			}return n[i].exports;
		}for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
			o(t[i]);
		}return o;
	}return r;
})()({ 1: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				exampleSelector: $('.js-something')
			},

			classes: {
				exampleShow: 'show-something'
			},

			attr: {
				exampleDataAttr: 'data-something'
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				(function () {
					var accordion = function accordion(settings) {
						var self = this;
						self.button = settings.button;
						self.wrap = settings.wrap;
						self.cont = settings.cont || settings.wrap;
						self.openClass = 'open';
						self.closeOnBlur = settings.closeOnBlur;
						self.slide = settings.slide;

						self.closeAccordian = function () {
							self.button.removeClass(self.openClass);
							if (self.slide !== false) {
								self.wrap.removeClass(self.openClass).stop().slideUp(300);
							} else {
								self.wrap.removeClass(self.openClass);
							}
						};

						self.init = function () {
							self.button.on('click', function () {
								self.button.toggleClass(self.openClass);
								if (self.slide !== false) {
									self.wrap.toggleClass(self.openClass).stop().slideToggle(300);
								} else {
									self.wrap.toggleClass(self.openClass);
								}
							});

							function closeAcc() {
								self.closeAccordian();
							}

							if (self.closeOnBlur !== false) {
								Global.functions.clickOutsideContainer($('body'), self.cont, $(), closeAcc);
							}
						};

						self.init();
					};

					if ($('[data-acc-btn]').length > 0) {
						$('[data-acc-btn]').each(function () {
							var index = $(this).attr('data-acc-btn');
							var accordionGeneric = new accordion({
								button: $(this),
								wrap: $('[data-acc-content="' + index + '"]'),
								closeOnBlur: false
							});
						});
					}
				})();
			}
		};
	}, { "./global": 4 }], 2: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				wpAdminBar: $('#wpadminbar'),
				siteHeader: $('.site-header')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				var $dom = this.$dom;

				function moveIfAdminBar() {
					$dom.siteHeader.css('top', $dom.wpAdminBar.height());
					$dom.wpAdminBar.css('position', 'fixed');
				};

				function addGlobalContentOffset() {
					var admBarH = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

					$('.global-margin-top').css('margin-top', $dom.siteHeader[0].getBoundingClientRect().bottom + 'px');
					$('.global-padding-top').css('padding-top', $dom.siteHeader[0].getBoundingClientRect().bottom + 'px');
					$('.global-admin-margin').css('margin-top', admBarH + 'px');
				}

				if ($dom.wpAdminBar.length > 0) {
					moveIfAdminBar();
					addGlobalContentOffset($dom.wpAdminBar.height());
				} else {
					addGlobalContentOffset();
				}

				Global.$dom.window.on('resize', function () {
					clearTimeout(window.resizedFinished);
					window.resizedFinished = setTimeout(function () {
						if ($dom.wpAdminBar.length > 0) {
							moveIfAdminBar();
							addGlobalContentOffset($dom.wpAdminBar.height());
						} else {
							addGlobalContentOffset();
						}
					}, 250);
				});
			}
		};
	}, { "./global": 4 }], 3: [function (require, module, exports) {
		"use strict";

		var Global = require("./global"); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				exampleSelector: $(".js-something")
			},

			classes: {
				exampleShow: "show-something"
			},

			attr: {
				exampleDataAttr: "data-something"
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var $dom = this.$dom;
				var classes = this.classes;
				var attr = this.attr;
				var ww = Global.vars.windowWidth; // this variable is called from global.js file

				var acc = document.getElementsByClassName("accordion-as");
				var i;

				for (i = 0; i < acc.length; i++) {
					acc[i].addEventListener("click", function () {
						this.classList.toggle("active");
						var panel = this.nextElementSibling;
						if (panel.style.maxHeight) {
							panel.style.maxHeight = null;
							this.classList.remove("active-button");
						} else {
							panel.style.maxHeight = panel.scrollHeight + "px";
							this.classList.add("active-button");
						}
					});
				}
			}
		};
	}, { "./global": 4 }], 4: [function (require, module, exports) {
		"use strict";

		module.exports = {
			$dom: {
				window: $(window),
				body: $('body')
			},

			vars: {
				windowWidth: window.innerWidth
			},

			functions: {
				escKey: function escKey(callback) {
					$(document).on('keyup', function (e) {
						if (e.keyCode === 27) {
							callback();
						}
					});
				},

				clickOutsideContainer: function clickOutsideContainer(selector, container, closeBtn, callback) {
					selector.on('mouseup', function (e) {
						e.preventDefault();
						if (!container.is(e.target) && container.has(e.target).length === 0 && !closeBtn.is(e.target)) {
							callback();
						}
					});
				}
			}
		};
	}, {}], 5: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				menuBtn: $('.js-menu-btn'),
				menuNav: $('.js-main-nav'),
				menuHasSub: $('.menu-item-has-children')
			},

			classes: {
				open: 'open',
				openMenu: 'menu-open'
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var $dom = this.$dom;
				var classes = this.classes;

				// functions
				function closeNav() {
					$dom.menuBtn.removeClass(classes.open);
					$dom.menuNav.removeClass(classes.open);
					Global.$dom.body.removeClass(classes.openMenu);
				}

				if (Global.vars.windowWidth < 768) {
					$dom.menuHasSub.each(function (i, el) {
						$(el).append('<span class="sub-icon font-plus-circle" data-open-sub></span>');
					});
				}

				// bind events
				$dom.menuBtn.on('click', function (e) {
					e.preventDefault();
					$dom.menuBtn.toggleClass(classes.open);
					$dom.menuNav.toggleClass(classes.open);
					Global.$dom.body.toggleClass(classes.openMenu);
				});

				$dom.menuNav.on('click', '[data-open-sub]', function () {
					if (Global.vars.windowWidth < 768) {
						$(this).siblings('.sub-menu').slideToggle();
					}
				});

				Global.functions.clickOutsideContainer($dom.menuNav, $dom.menuNav.children('ul'), $dom.menuBtn, closeNav);

				Global.functions.escKey(closeNav);
			}
		};
	}, { "./global": 4 }], 6: [function (require, module, exports) {
		"use strict";

		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				sliderHeroBig: $('.js-slider-hero-big'),
				sliderHeroSmall: $('.js-slider-hero-small'),
				sliderBrands: $('.js-slider-brands'),
				sliderFeaturedProducts: $('.js-slider-featured-products')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var $dom = this.$dom;

				// slider Hero Big
				$dom.sliderHeroBig.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 10000,
					speed: 3000,
					arrows: false,
					fade: true,
					pauseOnHover: false,
					asNavFor: $dom.sliderHeroSmall
				});

				$('.js-banner-big__arrow-left').click(function () {
					$($dom.sliderHeroBig).slick('slickPrev');
				});

				$('.js-banner-big__arrow-right').click(function () {
					$($dom.sliderHeroBig).slick('slickNext');
				});

				// slider Hero Small
				$dom.sliderHeroSmall.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 10000,
					speed: 3000,
					arrows: false,
					fade: true,
					pauseOnHover: false
				});

				// slider Brands
				$dom.sliderBrands.slick({
					infinite: true,
					slidesToShow: 4,
					slidesToScroll: 1,
					autoplay: true,
					arrows: false,
					autoplaySpeed: 0,
					speed: 5000,
					cssEase: 'linear',
					draggable: false,
					responsive: [{
						breakpoint: 991,
						settings: {
							slidesToShow: 3
						}
					}, {
						breakpoint: 767,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 479,
						settings: {
							slidesToShow: 1
						}
					}]
				});

				// slider Featured Products
				$dom.sliderFeaturedProducts.slick({
					infinite: true,
					slidesToShow: 5,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 4000,
					speed: 1000,
					arrows: false,
					responsive: [{
						breakpoint: 1650,
						settings: {
							slidesToShow: 4
						}
					}, {
						breakpoint: 1250,
						settings: {
							slidesToShow: 3
						}
					}, {
						breakpoint: 768,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 500,
						settings: {
							slidesToShow: 1
						}
					}]
				});

				$('.js-featured-products__arrow-left').click(function () {
					$($dom.sliderFeaturedProducts).slick('slickPrev');
				});

				$('.js-featured-products__arrow-right').click(function () {
					$($dom.sliderFeaturedProducts).slick('slickNext');
				});
			}
		};
	}, {}], 7: [function (require, module, exports) {
		jQuery(function ($) {
			// Site Menu
			var menu = require('./_site/menu');
			menu.init();

			// Slick Slider
			var sliders = require('./_site/sliders');
			sliders.init();

			// Accordion
			var accordionSection = require('./_site/accordion');
			accordionSection.init();

			// Admin Menu
			var adminHeader = require('./_site/adminHeader');
			//adminHeader.init();

			// Filter Accordion
			var filterAccordionSection = require('./_site/filterAccordion');
			//filterAccordionSection.init();


			/***********************************************
    *** Adding labels on contact form
   ***********************************************/

			$(document).on('focus', '.contact-form-wrap input[type="text"], .contact-form-wrap input[type="email"], .contact-form-wrap input[type="tel"], .contact-form-wrap textarea, .comment-form-author input[type="text"], .comment-form-email input[type="email"], .comment-form-comment textarea', function (e) {
				var ph = $(this).attr('placeholder');
				if (ph) {
					$(this).attr('ph', $(this).attr('placeholder'));
					$(this).attr('placeholder', '');
					$(this).animate({ 'position': 'relative' }, 100);
					$(this).parent().append('<div class="floating-label">' + ph + '</div>');
					$(this).parent().find('.floating-label').fadeIn();
				}
			});

			/***********************************************
    *** Disabling validation on commentform
   ***********************************************/

			$("#commentform").removeAttr("novalidate");

			/***********************************************
    *** Showcase section
   ***********************************************/

			$('#showcase-tab-1').addClass('active');
			$('.products-showcase__content-wrapper#showcase-tab-1').css('display', 'block');

			var myTimeout = setTimeout(function () {});

			var isActive = false;

			$('.showcase-tab-link').on('click', function () {
				if (isActive || this.classList.contains('active')) return;
				isActive = true;
				clearTimeout(myTimeout);
				$('.showcase-tab-link').removeClass('active');
				$(this).addClass('active');
				var tabId = event.target.id;
				$('.products-showcase__content-wrapper').fadeOut(500);

				myTimeout = setTimeout(function () {
					$('.products-showcase__content-wrapper#' + tabId).fadeIn(500, function () {
						isActive = false;
					});
				}, 500);
			});

			/***********************************************
   *** Changing text in Filter price section
   ***********************************************/

			$('.priceLabel .price-text').text('Preis:');

			/***********************************************
    *** Enabling masonry
   ***********************************************/

			$('.grid').masonry({
				itemSelector: '.grid-item'
			});

			/***********************************************
    *** Search bar mobile
   ***********************************************/

			if (window.innerWidth < 768) {
				$('#woocommerce_product_search-2 button[type="submit"]').click(function () {
					event.preventDefault();
					$('#woocommerce_product_search-2 .search-field').addClass('active');
				});

				$('body').click(function () {
					$('#woocommerce_product_search-2 .search-field').removeClass('active');
				});

				$('body').on('click', '#woocommerce_product_search-2 button[type="submit"]', function (event) {
					event.stopPropagation();
					event.preventDefault();
				});

				$('body').on('click', '#woocommerce_product_search-2 .search-field.active', function (event) {
					event.stopPropagation();
					event.preventDefault();
				});

				$('#woocommerce_product_search-2 .search-field').on('keypress', function (event) {
					if (event.keyCode == 13) {
						$('.woocommerce-product-search').unbind('submit').submit();
						return false;
					}
				});
			}

			/***********************************************
    *** Shop infos & hilfe menu mobile
   ***********************************************/

			$('.shop-info-menu-btn').on('click', function () {
				if ($('.shop-info-menu').hasClass('active')) {
					$('.shop-info-menu').removeClass('active');
				} else {
					$('.shop-info-menu').addClass('active');
				}
			});

			$('body').click(function () {
				$('.shop-info-menu').removeClass('active');
			});

			$('body').on('click', '.shop-info-menu-btn', function (event) {
				event.stopPropagation();
				event.preventDefault();
			});

			/***********************************************
    *** Section Filters mobile
   ***********************************************/

			$('.accordion-as').on('click', function () {
				if ($(this).hasClass('active-button')) {
					$(this).removeClass('active-button');
					$('.panel-as').removeClass('visible');
				} else {
					$(this).addClass('active-button');
					$('.panel-as').addClass('visible');
				}
			});

			/***********************************************
    *** Changing GDPR button text
   ***********************************************/

			$('button.gdpr-agreement').text('Zustimmen');

			/***********************************************
    *** AOS animations
   ***********************************************/

			AOS.init({
				duration: 1200
			});
		});
	}, { "./_site/accordion": 1, "./_site/adminHeader": 2, "./_site/filterAccordion": 3, "./_site/menu": 5, "./_site/sliders": 6 }] }, {}, [7]);